// 我的提交列表页
<template>
  <div class="my_submit">
    <i-nav-bar :iTitle="title"></i-nav-bar>
    <div class="main">
      <div class="main_num">共{{ list.length || 0 }}条</div>
      <div class="main_list">
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text=""
          @load="onLoad"
        >
          <div
            class="item mgt20"
            v-for="(item, index) in list"
            :key="index"
            @click="toPage('/mySubmit', { id: item.info_id })"
          >
            <div class="img_box">
              <img
                class="star_img"
                :src="
                  item.img_url ||
                  require('./../../assets/images/ido/pic_star_default.png')
                "
                alt=""
              />
            </div>
            <div class="item_content">
              <div class="content_t">
                <span>{{ item.star_name }}</span>
                <span>{{ item.scene_name }}</span>
              </div>
              <div class="content_b">
                <span>{{ fontText(item.con || "", 16) }}</span>
              </div>
            </div>
          </div>
        </van-list>
      </div>
    </div>

    <!--  底部按钮  -->
    <div class="footer">
      <van-button class="footer_btn btn1" color="#111111" round @click="goBack">
        <img
          class="left_img"
          src="./../../assets/images/ido/pic_left.png"
          alt=""
        />
      </van-button>
      <van-button
        class="footer_btn btn2 mgl10"
        color="#4BFFF2"
        :disabled="isDisabled"
        round
        @click="toPage('/chooseIdo')"
        >继续提交
        <img
          class="left_img"
          src="./../../assets/images/ido/pic_right.png"
          alt=""
        />
      </van-button>
    </div>
  </div>
</template>

<script>
import iNavBar from "@/components/iNavBar.vue";
import { myInfo } from "@/request/api";
import { fontText } from "@/utils/utils";

export default {
  name: "List",
  components: {
    iNavBar,
  },
  data() {
    return {
      title: "我的提交",
      list: [],
      isDisabled: false,
      loading: false,
      finished: false,
      fontText,
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    onLoad() {
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      // for (let i = 0; i < 10; i++) {
      //   this.list.push(this.list.length + 1);
      // }
      // 加载状态结束
      this.loading = false;
      this.finished = true;
      // 数据全部加载完成
      // if (this.list.length >= 40) {
      //   this.finished = true;
      // }
    },

    init() {
      LA.track('myuploadpage_show');
      _hmt.push(["_trackEvent", "myuploadpage_show", "show"]);
      this.getData();
    },

    // 获取数据
    async getData() {
      let params = {
        token: localStorage.getItem("token") || "",
      };
      let data = await myInfo(params);
      console.log("返回的数据", data);
      this.list = data.details.list || [];
    },

    toPage(url = "/home", query = {}) {
      if (url == "/chooseIdo") {
        LA.track('con_submit2_click');
        _hmt.push(["_trackEvent", "con_submit2_click", "click"]);
      }
      this.$router.push({
        path: url,
        query,
      });
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="less" scoped>
.my_submit {
  width: 100%;
  min-height: 100%;
  background: #5257cf;
  .main_num {
    height: 20px;
    line-height: 20px;
    background: #111111;
    font-size: 12px;
    font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
    font-weight: normal;
    color: #4bfff2;
    text-align: center;
  }

  .main_list {
    margin-top: 30px;
    padding-bottom: 50px;
    .item {
      position: relative;
      box-sizing: border-box;
      width: 86%;
      height: 84px;
      padding-left: 28px;
      padding-right: 32px;
      margin: 0 auto;
      margin-left: 36px;
      background: url("./../../assets/images/mySubmit/back_list_item.png")
        no-repeat 96% 100%;
      background-size: contain;
      .img_box {
        position: absolute;
        top: 0;
        bottom: 0;
        left: -22px;
        margin: auto;
        width: 41px;
        height: 41px;
        border-radius: 50%;
        background: #6f87ff;
        border: 2px solid #4bfff2;
        .star_img {
          position: absolute;
          width: 41px;
          height: 41px;
          border-radius: 50%;
          object-fit: contain;
          bottom: 0;
        }
      }

      .item_content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 96%;
        height: 100%;
        margin: 0 auto;
        // background-color: yellow;
        font-size: 14px;
        font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
        font-weight: normal;
        color: #ffffff;
        .content_t {
          display: flex;
          justify-content: space-between;
          color: #4bfff2;
        }
        .content_b {
          margin-top: 4px;
          text-align: justify;
        }
      }
    }
  }

  .footer {
    box-sizing: border-box;
    border-top: 0.5px solid #484dc6;
    background-color: #5257cf;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 0;
    width: 100%;
    padding: 10px 20px;
    z-index: 10;

    .footer_btn {
      width: 100%;
      height: 37px;

      .van-button__text {
        font-size: 14px;
        font-family: Alibaba-PuHuiTi-M, Alibaba-PuHuiTi;
        font-weight: normal;
        color: #111111;
        line-height: 20px;
      }
    }
    .left_img {
      width: 22px;
      height: 10px;
      object-fit: contain;
    }
    .btn1 {
      position: relative;
      width: 120px;
      background: #111111;
      .van-button__text {
        font-size: 14px;
        font-family: Alibaba-PuHuiTi-M, Alibaba-PuHuiTi;
        font-weight: normal;
        color: #4bfff2;
        line-height: 20px;
      }
    }
    .btn2 {
      font-size: 14px;
      font-family: Alibaba-PuHuiTi-M, Alibaba-PuHuiTi;
      font-weight: normal;
      color: #111111;
    }
  }
}
</style>